import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import "./styles.css"
import { useTranslation } from "../../../utils"
import TargetIcon from "../../../images/scoring-target.svg"
import EuroIcon from "../../../images/scoring-euro.svg"
import ShovelIcon from "../../../images/scoring-shovel.svg"

const AboutCriticalSection = () => {
  const { t } = useTranslation()

  return (
    <section className="about-critical-section">
      <div className="container">
        <div className="about-critical-section__content">
          <h1 className="about-critical-section__title">
            <FormattedMessage
              id="Why it is critical?"
              defaultMessage="Why it is critical?"
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </h1>
          <p className="about-critical-section__description">
            {t(
              `According to Towergate Insurance, SMBs often underestimate their risk level, with 82% of SMB owners saying they're not targets for attacks. They believe that, researchers said, because they feel they "don't have anything worth stealing."`
            )}
          </p>
        </div>
        <div className="about-critical-section__items">
          <div className="about-critical-section__item">
            <div className="about-critical-section__icon">
              <img src={TargetIcon} alt="Target" />
            </div>
            <p className="about-critical-section__desc">
              Recent statistics show that around 60% of SMBs forced to suspend
              operations after a cyber attack never reopen for business.
            </p>
          </div>
          <div className="about-critical-section__item">
            <div className="about-critical-section__icon">
              <img src={EuroIcon} alt="Target" />
            </div>
            <p className="about-critical-section__desc">
              The average lost from a single attack is 19,000EUR
            </p>
          </div>
          <div className="about-critical-section__item">
            <div className="about-critical-section__icon">
              <img src={ShovelIcon} alt="Target" />
            </div>
            <p className="about-critical-section__desc">
              Even though 67% of SMBs were actually hit by a cyberattack in
              2019.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutCriticalSection

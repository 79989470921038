import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import "./styles.css"
import { useTranslation } from "../../../utils"
const AboutCyberSection = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "cyber.png" }) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="about-cyber-section container wt-flex-column">
      <div className="about-cyber-section__block container wt-flex wt-flex-middle">
        <div className="about-cyber-section__content">
          <h1 className="about-cyber-section__title">
            <FormattedMessage
              id="What is a Cyber Risk?"
              defaultMessage="What is a Cyber Risk?"
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </h1>
          <p className="about-cyber-section__description">
            {t(
              "Cyber risk is a business risk, no matter how small or big your company is. Even with the best security in place, there's still a risk of a cyber. While more and more businesses are shifting their mindset in cybersecurity from questioning if their company will experience an attack, to when will they be threatened and how will they respond, they still need to address cybersecurity as the business risk it is. "
            )}
          </p>
        </div>
        <div className="wt-flex-1 about-cyber-section__image">
          <div data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutCyberSection

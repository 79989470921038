import React, { useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import AboutMainSection from "../../sections/scoring-about/about-main"
import Footer from "../../sections/footer"
import AboutCyberSection from "../../sections/scoring-about/about-cyber"
import AboutCriticalSection from "../../sections/scoring-about/about-critical"
import AboutProvideSection from "../../sections/scoring-about/about-provide"
import AboutScoringSection from "../../sections/scoring-about/about-scoring"
import { getQueryParams } from "../../utils"
import { navigate } from "gatsby"

const AboutPage = ({ location }) => {
  useEffect(() => {
    const url = getQueryParams(location.search)["url"]
    const email = getQueryParams(location.search)["email"]

    if (url || email) {
      navigate(
        "/scoring/scan/?" +
          (url ? `url=${url}` + (email && "&") : "") +
          (email ? `email=${email}` : "")
      )
    }
  }, [location])

  return (
    <Layout>
      <SEO title="Do you know your Cyber Risk Score?" />
      <Header />
      <AboutMainSection />
      <AboutScoringSection />
      <AboutCyberSection />
      <AboutCriticalSection />
      <AboutProvideSection />
      <Footer />
    </Layout>
  )
}

export default AboutPage

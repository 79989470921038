import React from "react"
import "./styles.css"
import { useTranslation } from "../../../utils"
import ScoringForm from "../../../components/scoring-form"

const AboutScoringSection = () => {
  const { t } = useTranslation()

  return (
    <section className="about-scoring-section">
      <div className="container">
        <ScoringForm />
        <div className="about-scoring__block">
          <p>
            * WebTotem uses externally gathered data to assess your risk
            exposure. No aggressive scanning is held. No installation required.
          </p>
        </div>
      </div>
    </section>
  )
}

export default AboutScoringSection

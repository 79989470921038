import React, { useState } from "react"
import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import "./styles.css"
import TargetIcon from "../../../images/scoring-target.svg"
import { useAnalytics } from "../../../hooks/useTracking"

const AboutProvideSection = () => {
  const analytics = useAnalytics()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "provid.png" }) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [currentNavContent, setCurrentNavContent] = useState(1)
  const openClose = number => {
    if (number === currentNavContent) {
      setCurrentNavContent(0)
    } else {
      setCurrentNavContent(number)
    }
  }

  return (
    <section className="about-provide-section container wt-flex-column">
      <h1 className="about-provide-section__title">
        <FormattedMessage
          id="What we provide you"
          defaultMessage="What we provide you"
          values={{
            blue: (...chunks) => (
              <span className="wt-text wt-text--blue">{chunks}</span>
            ),
            span: (...chunks) => <span>{chunks}</span>,
          }}
        />
      </h1>
      <div className="about-provide-section__body">
        <div className="about-provide-section__navbar wt-flex-1">
          <div
            className={classNames("about-provide-section__navbar-item", {
              active: currentNavContent === 1,
            })}
            onClick={() => {
              analytics.showAnswer("Assess your risks")
              setCurrentNavContent(1)
            }}
          >
            Assess your risks
          </div>
          <div
            className={classNames("about-provide-section__navbar-item", {
              active: currentNavContent === 2,
            })}
            onClick={() => {
              analytics.showAnswer("See what hackers can see")
              setCurrentNavContent(2)
            }}
          >
            See what hackers can see
          </div>
          <div
            className={classNames("about-provide-section__navbar-item", {
              active: currentNavContent === 3,
            })}
            onClick={() => {
              analytics.showAnswer("Assessed risks")
              setCurrentNavContent(3)
            }}
          >
            Assessed risks
          </div>
          <div
            className={classNames("about-provide-section__navbar-item", {
              active: currentNavContent === 4,
            })}
            onClick={() => {
              analytics.showAnswer("How it works")
              setCurrentNavContent(4)
            }}
          >
            How it works
          </div>
        </div>
        <div className="about-provide-section__content wt-flex-2">
          {currentNavContent === 1 && (
            <>
              <div className="about-provide-section__gray-bg">
                <Img
                  fluid={data.placeholderImage.childImageSharp.fluid}
                  style={{ maxWidth: "465px", margin: "auto" }}
                />
              </div>
              <div className="about-provide-section__service-desc">
                <div className="about-provide-section__content-title wt-margin-top-40 wt-margin-bottom-30">
                  Assess your risks
                </div>
                <div>
                  Cyber risk assessment is a first important step in taking
                  proactive measures to safeguard against cyber risks. To
                  understand how your business is exposed we prepared a Free
                  Cyber Risk Assessment with recommendations to reduce your
                  risks.
                </div>
              </div>
            </>
          )}
          {currentNavContent === 2 && (
            <div className="about-provide-section__gray-bg wt-padding-50">
              <div className="about-provide-section__content-title wt-margin-bottom-25">
                <span className="about-provide-section__black-text">
                  See what hackers
                </span>{" "}
                can see
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="about-provide-section__circle">
                  <img
                    src={TargetIcon}
                    alt="Target"
                    className="about-provide-section__content-item-badge"
                  />
                </div>
                Discover company’s risks in security, reputation and
                attractiveness.
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="about-provide-section__circle">
                  <img
                    src={TargetIcon}
                    alt="Target"
                    className="about-provide-section__content-item-badge"
                  />
                </div>
                Find exploitable weaknesses and misconfigurations that expose
                your business to cyber threats
              </div>
              <div className="about-provide-section__content-item">
                <div className="about-provide-section__circle">
                  <img
                    src={TargetIcon}
                    alt="Target"
                    className="about-provide-section__content-item-badge"
                  />
                </div>
                Get clear, actionable steps to reduce your organization’s cyber
                risks
              </div>
            </div>
          )}
          {currentNavContent === 3 && (
            <div className="about-provide-section__gray-bg">
              <table>
                <tbody>
                  <tr>
                    <td>Website Application Security</td>
                    <td>Content Security Policy</td>
                  </tr>
                  <tr>
                    <td>Email Security</td>
                    <td>External Network Security</td>
                  </tr>
                  <tr>
                    <td>Traffic Encryption</td>
                    <td>Compliance</td>
                  </tr>
                  <tr>
                    <td>Subdomains Security</td>
                    <td>IP/Domain Reputation</td>
                  </tr>
                  <tr>
                    <td>Mentions on the Dark Web</td>
                    <td>Potential Phishing</td>
                  </tr>
                  <tr>
                    <td>Leaked Information</td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {currentNavContent === 4 && (
            <div className="about-provide-section__gray-bg wt-padding-50">
              <div className="about-provide-section__content-title wt-margin-bottom-25">
                <span className="about-provide-section__black-text">
                  How it works?
                </span>
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="about-provide-section__circle">
                  <div className="about-provide-section__content-item-badge">
                    1
                  </div>
                </div>
                On the scoring page, enter your e-mail and address in the input
                fields{" "}
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="about-provide-section__circle">
                  <div className="about-provide-section__content-item-badge">
                    2
                  </div>
                </div>
                After the entered data, you will see a page with the scoring
                results{" "}
              </div>
              <div className="about-provide-section__content-item">
                <div className="about-provide-section__circle">
                  <div className="about-provide-section__content-item-badge">
                    3
                  </div>
                </div>
                We crawl your site and the received data will help you make your
                site safe{" "}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="about-provide-section__body-mobile">
        <div
          className={classNames("about-provide-section__navbar-item", {
            active: currentNavContent === 1,
          })}
          onClick={() => openClose(1)}
        >
          Assess your risks
        </div>
        {currentNavContent === 1 && (
          <div className="about-provide-section__content wt-flex-2">
            <div className="about-provide-section__gray-bg">
              <Img
                fluid={data.placeholderImage.childImageSharp.fluid}
                style={{ maxWidth: "465px", margin: "auto" }}
              />
            </div>
            <div className="about-provide-section__service-desc">
              <div className="about-provide-section__content-title wt-margin-top-40 wt-margin-bottom-30">
                Assess your risks
              </div>
              <div>
                Cyber risk assessment is a first important step in taking
                proactive measures to safeguard against cyber risks. To
                understand how your business is exposed we prepared a Free Cyber
                Risk Assessment with recommendations to reduce your risks.
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames("about-provide-section__navbar-item", {
            active: currentNavContent === 2,
          })}
          onClick={() => openClose(2)}
        >
          See what hackers can see
        </div>
        {currentNavContent === 2 && (
          <div className="about-provide-section__content wt-flex-2">
            <div className="about-provide-section__gray-bg wt-padding-20">
              <div className="about-provide-section__content-title wt-margin-bottom-25">
                <span className="about-provide-section__black-text">
                  See what hackers
                </span>{" "}
                can see
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="wt-flex wt-flex-center wt-margin-bottom-15">
                  <div className="about-provide-section__circle">
                    <img
                      src={TargetIcon}
                      alt="Target"
                      className="about-provide-section__content-item-badge"
                    />
                  </div>
                </div>
                Discover company’s risks in security, reputation and
                attractiveness.
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="wt-flex wt-flex-center wt-margin-bottom-15">
                  <div className="about-provide-section__circle">
                    <img
                      src={TargetIcon}
                      alt="Target"
                      className="about-provide-section__content-item-badge"
                    />
                  </div>
                </div>
                Find exploitable weaknesses and misconfigurations that expose
                your business to cyber threats
              </div>
              <div className="about-provide-section__content-item">
                <div className="wt-flex wt-flex-center wt-margin-bottom-15">
                  <div className="about-provide-section__circle">
                    <img
                      src={TargetIcon}
                      alt="Target"
                      className="about-provide-section__content-item-badge"
                    />
                  </div>
                </div>
                Get clear, actionable steps to reduce your organization’s cyber
                risks
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames("about-provide-section__navbar-item", {
            active: currentNavContent === 3,
          })}
          onClick={() => openClose(3)}
        >
          Assessed risks
        </div>
        {currentNavContent === 3 && (
          <div className="about-provide-section__content wt-flex-2">
            <div className="about-provide-section__gray-bg">
              <table>
                <tbody>
                  <tr>
                    <td>Website Application Security</td>
                    <td>Content Security Policy</td>
                  </tr>
                  <tr>
                    <td>Email Security</td>
                    <td>External Network Security</td>
                  </tr>
                  <tr>
                    <td>Traffic Encryption</td>
                    <td>Compliance</td>
                  </tr>
                  <tr>
                    <td>Subdomains Security</td>
                    <td>IP/Domain Reputation</td>
                  </tr>
                  <tr>
                    <td>Mentions on the Dark Web</td>
                    <td>Potential Phishing</td>
                  </tr>
                  <tr>
                    <td>Leaked Information</td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div
          className={classNames("about-provide-section__navbar-item", {
            active: currentNavContent === 4,
          })}
          onClick={() => openClose(4)}
        >
          How it works
        </div>
        {currentNavContent === 4 && (
          <div className="about-provide-section__content wt-flex-2">
            <div className="about-provide-section__gray-bg wt-padding-20">
              <div className="about-provide-section__content-title wt-margin-bottom-25">
                <span className="about-provide-section__black-text">
                  How it works?
                </span>
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="wt-flex wt-flex-center wt-margin-bottom-15">
                  <div className="about-provide-section__circle">
                    <div className="about-provide-section__content-item-badge">
                      1
                    </div>
                  </div>
                </div>
                On the scoring page, enter your e-mail and address in the input
                fields{" "}
              </div>
              <div className="about-provide-section__content-item wt-margin-bottom-25">
                <div className="wt-flex wt-flex-center wt-margin-bottom-15">
                  <div className="about-provide-section__circle">
                    <div className="about-provide-section__content-item-badge">
                      2
                    </div>
                  </div>
                </div>
                After the entered data, you will see a page with the scoring
                results{" "}
              </div>
              <div className="about-provide-section__content-item">
                <div className="wt-flex wt-flex-center wt-margin-bottom-15">
                  <div className="about-provide-section__circle">
                    <div className="about-provide-section__content-item-badge">
                      3
                    </div>
                  </div>
                </div>
                We crawl your site and the received data will help you make your
                site safe{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default AboutProvideSection

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import "./styles.css"
import { useTranslation } from "../../../utils"
import WaveImg from "../../../images/main-wave.svg"
import { useAnalytics } from "../../../hooks/useTracking"

const AboutMainSection = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "scoring.png" }) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const analytics = useAnalytics()

  return (
    <section className="about-main-section">
      <div className="about-main-section__backgrounds">
        <div className="about-main-section__item about-main-section__item_gray"></div>
        <img src={WaveImg} alt="Wave" className="about-main-section__wave" />
        <div className="about-main-section__item about-main-section__item_white"></div>
      </div>
      <div className="about-main-section__offer container wt-flex wt-flex-middle">
        <div className="about-main-section__content">
          <h1 className="about-main-section__title">
            <FormattedMessage
              id="Do you know your Cyber Risk Score?"
              defaultMessage="Do you know your Cyber Risk Score?"
              values={{
                blue: (...chunks) => (
                  <span className="wt-text wt-text--blue">{chunks}</span>
                ),
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </h1>
          <p className="about-main-section__description">
            {t(
              "Understand your business’s cyber risks. Learn how to reduce them."
            )}
          </p>
          <Link
            onClick={analytics.clickGetStarted}
            to="/pricing"
            className="wt-button wt-button--primary wt-button--lg wt-margin-bottom-15"
          >
            {t("Get started")}
          </Link>
        </div>
        <div className="wt-flex-1 about-main-section__image">
          <div data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutMainSection
